@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
}
body{
    overflow-x: hidden;
}

a {
    text-decoration: none;
}

/* Terms & Conditions */
.container {
    display: flex;
    flex-direction: column;
    margin: 5% 20%;
}

.content-head {
    font-size: 24px;
    line-height: 30px;
    color: #213554;
    font-weight: 500;
}

.content-h3subhead {
    font-size: 20px;
    font-weight: 500;
    color: #213554;
    margin-top: 1rem;
}

.content-h4subhead {
    font-size: 18px;
    font-weight: 500;
    color: #213554;
    margin-top: 1rem;
}

.content-h5subhead {
    font-size: 16px;
    font-weight: 500;
    color: #213554;
    margin-top: 1rem;
}

.content-seperator {
    width: 28px;
    height: 5px;
    background-color: #213554;
    margin-top: 1rem;
}

.updated-date {
    color: #213554ab;
    font-weight: 500;
    margin-top: 1rem;
}

.content-text {
    font-size: 14px;
    line-height: 20px;
    color: #515978;
    margin: 1rem 0 0;
}

.list-item {
    display: list-item;
    padding-left: 0.5rem;
    margin-top: 1rem;
    font-size: 14px;
    line-height: 20px;
    color: #515978;
}

.disclaimer {
    font-size: 14px;
    line-height: 20px;
    color: #515978;
    margin-top: 2rem;
    font-weight: 500;
}

@media(width <= 981px) {
    .container {
        margin: 20% 10%;
    }
}