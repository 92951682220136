.start-learning-div{
    display: flex;
    padding: 5rem 8rem;
}
.start-learning-text-div h1{
    font-size: 50px;
    /* line-height: 78.02px; */
    font-weight: 800;
    color: #000000B2;
}
.start-learning-text-div span{
    color: #572DFE;
}
.start-learning-text-div{
    width: 50%;
}
.start-learning-image-div{
    width: 50%;
    position: relative;
}
.start-learning-text-div #start-learning-p{
    font-size: 18px;
    line-height: 23.26px;
    font-weight: 500;
    color: #727272;
    margin-top: 30px;
    margin-bottom: 60px;
}
.start-learning-text-div .options{
    display: flex;
    align-items: center;
    gap: 30px;
    margin-top: 20px;
}
.start-learning-text-div .options p{
    font-size: 19px;
    line-height: 29.26px;
    font-weight: 600;
    color: #727272;
}
.start-learning-text-div button{
    padding: 14px 24px;
    background-color: #572DFE;
    border: none;
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 600;
    margin-top: 30px;
}
.start-learning-text-div button:hover{
    background-color: #7e5efa;
    cursor: pointer;
}
.start-learning-image-div #computer{
    position: absolute;
    top: 40px;
    left: 40px;
}
.start-learning-image-div #computer{
    width: 510px;
}
.start-learning-image-div #ellipse{
    width: 560px;
    height: 550.29px;
}


@media (max-width: 640px ){
    .start-learning-div{
        padding: 5rem 1rem;
        flex-direction: column;
        gap: 40px;
    }
    .start-learning-text-div{
        width: 100%;
    }
    .start-learning-image-div{
        width: 100%;
        display: none;
    }
    .start-learning-image-div #ellipse{
        width: 100%;
        height: 100%;
    }
    .start-learning-image-div #computer{
        width: 100%;
        top: 30px;
        left: 30px;
    }
    .start-learning-text-div h1{
        font-size: 32px;
    }
    .start-learning-text-div #start-learning-p{
        font-size: 14px;
        margin-bottom: 45px;
    }
    .start-learning-text-div .options p{
        font-size: 16px;
    }
    .start-learning-text-div .options{
        gap: 20px;
    }
    .start-learning-text-div button{
        margin-top: 2rem;
    }
    
}