.best-platform{
    text-align: center;
}
.home-swiper2{
    position: relative;
}
.slide-image-div{
    z-index: 1 !important;
}
.swiper2-content{
    background-color: #ffffff;
    padding-left: 30px;
    padding-top: 70px;
    /* padding-right: 80px; */
    padding-bottom: 30px;
    text-align: start;
    border-radius: 25px;
    width: 331px;
    height: 283px;
}
.slide2-image-div{
    padding: 0;
}
.slide2-image-div img{
    margin-bottom: -28px;
    width: 160px;
    margin-left: 70px;
}
.slide2-image-div #slide2img2{
    margin-bottom: -46px;
}
.slide2-image-div #slide2img3{
    margin-bottom: -46px;
}
.best-platform-swiper{
    /* background-color: rgb(240, 236, 236); */
    background-color: #f9fbff;
}
.best-platform-swiper .swiper-slide{
    background-color: transparent !important;
}
.swiper2-content button{
    padding: 16px 26px;
    background-color: #E8EDF280;
    border-radius: 25px;
    color: #000000CC;
    font-size: 18px;
    font-weight: 700;
    /* line-height: 29.26px; */
    text-decoration: none;
    border: none;
    margin-bottom: 20px;
}
.swiper2-content button:hover{
    cursor: pointer;
    background-color: #c0c5ca80;
    transition: 0.5s ease;
}
.swiper2-content p{
    color: #00000080;
    font-size: 16px;
    line-height: 24.38px;
    font-weight: 600;
    margin-bottom: 20px;
}
.swiper2-content a{
    color: #0000B1;
    font-size: 18px;
    line-height: 29.26px;
    font-weight: 600;
    letter-spacing: 1px;
}
.swiper2-content a:hover{
    color: #694ed8;
}
.best-platform-swiper .swiper-pagination{
    margin-top: 80px !important;
    margin-bottom: 20px;
}

@media (max-width: 640px){
    .best-platform{
        padding: 2rem;
    }
    .best-platform h1{
        font-size: 22px;
    }
    .swiper2-content{
        width: 100%;
        padding: 0 1rem;
        padding-top: 60px;
        text-align: center;
        height: 260px;
    }
    .slide2-image-div img{
        margin: auto;
        margin-bottom: -28px;
        width: 130px;
    }
    .swiper2-content button{
        font-size: 14px;
        padding: 12px 18px;
        font-weight: 600;
        margin-bottom: 2rem;
    }
    .swiper2-content p{
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 5px;
    }
    .swiper2-content a{
        font-size: 16px;
        font-weight: 500;
    }
    .best-platform-swiper .swiper-pagination{
        margin-top: 1rem !important;
    }
}