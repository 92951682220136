.student-review-div{
    /* background-color: rgb(240, 236, 236); */
    background-color: #f9fbff;
}
.student-review-text{
    padding: 5rem 8rem;
}
.student-review-text h1{
    font-size: 38px;
    line-height: 58.51px;
    font-weight: 800;
    color: #000000B2;
    text-align: center;
}
.student-review-text span{
    color: #572DFE;
}
.student-review-swiper{
    padding: 0 8rem;
}
.student-review-slide{
    text-align: start;
    padding: 30px;
}
.student-review-slide img{
    width: 78px;
    height: 78px;
    margin-bottom: 20px;
}
.student-review-slide h3{
    font-size: 20px;
    line-height: 29.26px;
    font-weight: 700;
    color: #000000CC;
    margin-top: 10px;
}
.student-review-slide p{
    font-size: 16px;
    font-weight: 500;
    line-height: 24.38px;
    color: #727272;
    margin-top: 10px;
}
.student-review-swiper .swiper-slide{
    border-radius: 40px;
}
.student-review-swiper .swiper-pagination{
    margin-top: 60px !important;
    margin-bottom: 60px ;
}


@media (max-width: 640px){
    .student-review-text{
        padding: 2rem;
    }
    .student-review-text h1{
        font-size: 22px;
        line-height: 32px;
    }
    .student-review-swiper{
        padding: 0 1rem;
    }
    .student-review-slide{
        text-align: center;
        padding: 2rem 1rem;
    }
    .student-review-slide img{
        margin: auto;
        margin-bottom: 20px;
    }
    .student-review-swiper .swiper-pagination{
        margin-top: 1rem !important;
        margin-bottom: 3rem;
    }
}